<script setup lang="ts">
useTheme("nlc");
const { t } = useT();
const route = useRoute();
const loginGuard = useLoginGuard();
const { open, close } = useNlcModals();
const { checkDailyOpen } = useWelcomeModal(open, close);
const { data: appInit } = useAppInitData();
const { refresh: refreshWheelOfWins } = useDepositStreakData({ immediate: false });
const { SKIN_CAMP, refresh: refreshScratchCards } = useScretchCardData({ immediate: false });
const { sendAdTrigger } = usePopupAds();
const { isPWA } = useCheckPWA();
const { isIos } = useDevice();
const { checkDeepLink } = useDeepLink();
const { checkPendingBonus } = useWithoutDepositBonus(open, t);
const {
	depositStreakIsActive,
	prizeVaultIsActive,
	prizeDropsIsActive,
	scratchCardsIsActive,
	bingoLotteryIsActive,
	cosmicBoxesIsActive
} = useFeaturesIsActive();
const { resetPrizeDropTimer, lastWinners, duration, toNextPrizeDurationLeft, toNextPrizeDuration } = useAppPrizeDrops();

provide("prizeDrop", {
	resetPrizeDropTimer,
	lastWinners,
	duration,
	toNextPrizeDurationLeft,
	toNextPrizeDuration
});
useAppRefreshSocket(t);
useUploadDocumentsToast(
	"dark",
	"/nuxt-img/account/doc-notification-confirmed.png",
	"/nuxt-img/account/doc-notification-rejected.png"
);
useBingoSocket();
useTournamentSockets();
useStatusLeagueSockets();
useCheckPromoOffer(open);
useInitRegisterComplete();
usePresetPackageInit();

onMounted(() => {
	if ("scrollRestoration" in history) {
		history.scrollRestoration = "manual";
	}

	const { showTimeToast } = useCoinDropsToasts();
	usePrizeDropSocket({ t, open, showTimeToast, toNextPrizeDurationLeft, resetTimer: resetPrizeDropTimer });

	pushNotificationsDispatchEvent("init");
	checkDeepLink();

	const captcha = appInit.value?.captcha;

	if (captcha && captcha.isEnabled && captcha.key) {
		useHead({
			script: [
				{
					src: captcha.url,
					id: captcha.key,
					async: true,
					defer: true
				}
			]
		});
	}
	if (route.query.game) {
		open("LazyOModalGame");
	}
	loginGuard({
		success: () => {
			if (route.query?.openModal === "daily-wheel") {
				open("LazyOModalDailyWheel");
			}
			if (route.query?.freeEntriesBonus) {
				checkPendingBonus(route.query?.freeEntriesBonus as string);
			}
			if (prizeDropsIsActive.value) {
				checkDailyOpen({ storage: "showWelcomeCoinDrops", modal: "LazyOModalCoinDropsWelcome" });
			}
			if (depositStreakIsActive.value) {
				checkDailyOpen({ storage: "showWelcomeWheelOfWins", modal: "LazyOModalWheelOfWinsWelcome" });
				if (route.query?.openModal === "wheel-of-wins") {
					open("LazyOModalWheelOfWinsPlayWheel");
				}
			}
			if (prizeVaultIsActive.value) {
				checkDailyOpen({ storage: "showWelcomePrizeVault", modal: "LazyOModalPrizeVaultWelcome" });
				if (route.query?.openModal === "prize-vault") {
					open("LazyOModalPrizeVaultPlay");
				}
			}
			if (scratchCardsIsActive.value) {
				checkDailyOpen({
					storage: "showWelcomeScratchCards",
					modal: SKIN_CAMP ? "LazyOModalScratchCardsWelcomeCamp" : "LazyOModalScratchCardsWelcome"
				});
			}
			if (bingoLotteryIsActive.value) {
				checkDailyOpen({ storage: "showWelcomeBingo", modal: "LazyOModalBingoWelcome" });
			}
			if (cosmicBoxesIsActive.value) {
				checkDailyOpen({ storage: "showWelcomeCosmicBoxes", modal: "LazyOModalCosmicBoxesWelcome" });
			}
		}
	});
	if (isPWA.value && isIos) {
		updateServiceWorkers();
	}
});

useEvent(["nuxt:openWheelOfWinsHowItWorksModal"], () => {
	open("LazyOModalWheelOfWinsHowItWorks");
});
useEvent(["nuxt:openWheelOfWinsModal"], () => {
	open("LazyOModalWheelOfWinsPlayWheel");
});
useEvent(["nuxt:wheelOfWinsRefresh"], () => {
	refreshWheelOfWins();
});
useEvent(["nuxt:openWheelOfWinsWarningModal"], () => {
	open("LazyOModalWheelOfWinsCashDeskWarning");
});
useEvent(["nuxt:openPrizeVaultPlayModal"], () => {
	open("LazyOModalPrizeVaultPlay");
});
useEvent(["nuxt:openScratchCardsTicketPlayModal"], () => {
	open("LazyOModalScratchCardsTicketPlay");
});
useEvent(["nuxt:scratchCardsRefresh"], () => {
	refreshScratchCards();
});
useEvent(["nuxt:closeCashbox"], () => {
	sendAdTrigger("closeCashbox");
});
useEvent(["nuxt:openMagicBoxesPlayModal"], () => {
	open("LazyOModalCosmicBoxesSelection");
});
useEvent(["WEBSOCKET_RECONNECT"], () => {
	navigateTo("/", { external: true });
});
</script>
<template>
	<div>
		<NuxtPage />
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>
<style lang="scss">
.Toastify {
	position: relative;
	z-index: 10002;
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}

	&__toast:has(.badge) {
		overflow: visible;
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: -13px;
		width: 26px;
		height: 20px;
		border-radius: 4px;
		color: var(--neutral-100);
		font-size: 12px;
		background-color: var(--additional-a-3);
		z-index: 100;
	}

	.icon-favorite {
		background-image: url(/assets/icons/20/favorite.svg);
	}
}

#email-confirm-container {
	width: 320px;
	max-width: 100%;
	@include media-breakpoint-down(md) {
		transform: translateX(-50%);
		left: 50%;
		top: 8px;
	}
	.Toastify {
		&__toast {
			background: var(--custom-23);
		}
		&__toast-icon {
			display: none;
		}
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify {
		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				visibility: hidden;
				width: 0;
			}
		}
	}

	.Toastify__toast {
		min-width: 336px;
		width: 336px;
		top: 90px;
		height: auto;
		min-height: 96px;
		max-height: 96px;
		padding: 10px;
		background: var(--gradient-20);
		border-radius: 8px;
		box-shadow: 0px 4px 20px 0px var(--custom-rgb-13);

		@media (max-width: 1024px) {
			width: 336px;
		}

		@media only screen and (max-width: 480px) {
			top: 110px;
		}

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.Toastify__close-button {
			display: block;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
		}

		.Toastify__close-button > svg {
			height: 20px;
			width: 20px;
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 72px;
			height: 72px;
		}

		.toast-text {
			padding: 0 8px;
			line-break: auto;
			white-space: pre-line;
		}

		.toast-title {
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
			color: var(--neutral-99);
		}

		.toast-message {
			color: var(--neutral-70);
			font-size: 12px;
			font-weight: 300;
			line-height: 16px;
			padding-top: 8px;
		}
	}
}

#upload-document-cash-notification {
	.Toastify__toast {
		top: 0px;

		@media only screen and (max-width: 480px) {
			top: 20px;
		}
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

#notification-prize-drops {
	right: 16px;
	left: auto;
	transform: translateX(0);

	@include media-breakpoint-down(lg) {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	.Toastify {
		&__toast {
			padding: 12px;
			border: 2px solid var(--neutral-15);
			background: var(--neutral-15);

			&-body {
				white-space: normal;
			}
			&-icon {
				width: 40px;
			}
		}
		&__close-button {
			display: block;
			min-width: 20px;
			min-height: 20px;
			position: relative;
			opacity: 1;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(calc(-50% + 0.5px), -50%);
			}
		}
	}

	.text {
		color: var(--neutral-100);
		font-weight: 400;
		font-size: 12px;
	}
}
</style>
